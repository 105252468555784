import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	{ path: "", redirectTo: "home/", pathMatch: "full" },
	{
		path: "home/:id",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
	},
	{
		path: "welcome",
		loadChildren: () =>
			import("./welcome/welcome.module").then((m) => m.WelcomePageModule),
	},
	{
		path: "welcome",
		loadChildren: () =>
			import("./welcome/welcome.module").then((m) => m.WelcomePageModule),
	},
	{
		path: "step0",
		loadChildren: () =>
			import("./step0/step0.module").then((m) => m.Step0PageModule),
	},
	{
		path: "step1",
		loadChildren: () =>
			import("./step1/step1.module").then((m) => m.Step1PageModule),
	},
	{
		path: "step2",
		loadChildren: () =>
			import("./step2/step2.module").then((m) => m.Step2PageModule),
	},
	{
		path: "step3",
		loadChildren: () =>
			import("./step3/step3.module").then((m) => m.Step3PageModule),
	},
	{
		path: "step4",
		loadChildren: () =>
			import("./step4/step4.module").then((m) => m.Step4PageModule),
	},
	{
		path: "step5",
		loadChildren: () =>
			import("./step5/step5.module").then((m) => m.Step5PageModule),
	},
	{
		path: "step6",
		loadChildren: () =>
			import("./step6/step6.module").then((m) => m.Step6PageModule),
	},
	{
		path: "step7",
		loadChildren: () =>
			import("./step7/step7.module").then((m) => m.Step7PageModule),
	},
	{
		path: "step8",
		loadChildren: () =>
			import("./step8/step8.module").then((m) => m.Step8PageModule),
	},
	{
		path: "step9",
		loadChildren: () =>
			import("./step9/step9.module").then((m) => m.Step9PageModule),
	},
	{
		path: "step10",
		loadChildren: () =>
			import("./step10/step10.module").then((m) => m.Step10PageModule),
	},
	{
		path: "step41",
		loadChildren: () =>
			import("./step41/step41.module").then((m) => m.Step41PageModule),
	},
	{
		path: "politica",
		loadChildren: () =>
			import("./politica/politica.module").then((m) => m.PoliticaPageModule),
	},
	{
		path: "step71",
		loadChildren: () =>
			import("./step71/step71.module").then((m) => m.Step71PageModule),
	},
	{
		path: "step72",
		loadChildren: () =>
			import("./step72/step72.module").then((m) => m.Step72PageModule),
	},
	{
		path: "step73",
		loadChildren: () =>
			import("./step73/step73.module").then((m) => m.Step73PageModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
