import { Injectable, OnInit } from "@angular/core";

@Injectable()
export class DictonaryService implements OnInit {
	url = new Object();

	espanol = [];
	constructor() {}
	ngOnInit(): void {
		this.url["esp"] = {
			idioma: "esp",
			preguntas: {
				p1: " En una escala de 0 a 10 ¿Cómo valorarías tu satisfacción general con respecto a tu visita? ",
				p2: "  Indícanos por favor que secciones has visitado en tu última visita",
				p3: "Dinos por favor cuál de las siguientes frases describe mejor tu visita a la tienda:",
				p4: "Respecto a tu ultima visita  ¿En una escala de 0 a 10 cómo valorarías...?",
				p4b: "¿Por qué motivo no has realizado una compra?",
				p41: "Respecto a tu visita ¿En una escala de 0 a 10 cómo valorarías...?",
				p5: "¿En una escala de 0 a 10 recomendarías a un amigo o familiar nuestra tienda?",
				p6Promotor: "¿Qué aspectos destacas cuando nos recomiendas?",
				p6Neutral:
					"¿Qué aspectos deberíamos mejorar para que nos recomiendes más?",
				p6Detractor: "¿Por qué no nos recomiendas? ",
				p71: "Eres…",
				p72: "¿Con qué frecuencia nos visitas?",
				p73: "Indícanos tu edad",
				p74: "Hay niños en el hogar",
				p75: "¿Cuántos adultos viven en el hogar?",
				p8: "De estas marcas ¿cuál es la última que has comprado?",
				p9: "¿En una escala de 0 a 10 cuánto recomiendas a un amigo o familiar esta marca?",
				p10Promotor: "¿Qué aspectos destacas cuando recomiendas la marca?",
				p10Neutral:
					"¿Qué aspectos debería mejorar la marca para que la recomiendes más?",
				p10Detractor: "¿Por qué no recomienda la marca ?",
			},
			informativo: {
				text1: "¿Te ha gustado tu visita?",
				text2:
					"¡Cuéntanos tu experiencia de compra y participa en el sorteo de un set de productos  ICON!",
				text3:
					"Haz click aquí para dejarnos tu opinión. Solo te llevará 1 minuto.",
				text4: "¡Gracias por participar! Tu opinión nos ayuda a mejorar",
				text5: "¡Gracias!",
				text6:
					"Ahora solo tienes que dejarnos tus datos si quieres recibir todas nuestras novedades y promociones",
				text7: "Continuar",
				text8:
					"Indicanos tu correo electrónico o teléfono	 para participar en el sorteo.",
				text9:
					"Deseo recibir codigos promocionales, información relativa a ofertas, notificaciones digitales, newsletters, promoción de eventos y de ofertas genéricas o direccionadas, a través de la dirección de correo electrónico que proporciono",
				text10: "Aceptación de Política de Privacidad",
				text11: "Ingresa tu cuenta de correo o numero telefonico",
				text12:
					"Gracias por colaborar, tu opinión nos hace mejorar. Te invitamos a compartir tu experiencia en Google con otros usuarios.",
				text13: "Ninguna de estas",
			},
			categories: [
				{ id: 910, text: "Alimentación", isChecked: false },
				{ id: 901, text: "Productos capilares", isChecked: false },
				{ id: 911, text: "Refrigerados", isChecked: false },
				{ id: 912, text: "Aromaterapia", isChecked: false },
				{ id: 913, text: "Cosmética facial natural" },
				{ id: 914, text: "Suplementos y vitaminas ", isChecked: false },
				{ id: 915, text: "Cosmética corporal natural", isChecked: false },
				{ id: 916, text: "Té, Infusiones …", isChecked: false },
				{ id: 906, text: "Dietética y nutrición", isChecked: false },
			],
			options: {
				groupP3: {
					op1: "Compré un producto y recibí asesoramiento ",
					op2: "No compré, pero recibí asesoramiento",
					op3: "Compré un producto sin que me asesoren ",
					op4: "No compré y no recibí asesoramiento",
				},
				groupP4: {
					op1: "Nivel de disponibilidad y trato recibido ",
					op2: "Se preocuparon por conocer tu necesidades y el uso que le darás ",
					op3: "¿Te ofrecieron productos adecuados y te explicaron sus características y ventajas? ",
					op4: "¿Supieron resolver tus dudas o consultas? ",
					op5: "¿Te ofrecieron accesorios, servicios y/o visitar otras secciones?",
				},
				groupP4b: {
					op1: "Venía solo mirar o dar un paseo",
					op2: "No me han atendido",
					op3: "No encontré el producto que estaba buscando",
					op4: "El precio no me ha parecido atractivo",
				},
				groupP41: {
					op1: "Surtido y variedad de los productos en la tienda",
					op2: "Orden y limpieza de la tienda",
					op3: "Relación de la calidad /precio de los productos",
					op4: "Tiempo de espera en ser atendido",
				},
				groupP72: {
					op1: "Todos los meses",
					op2: "Cada dos meses",
					op3: "Con menos frecuencia",
					op4: "Es la primera vez que visitaba la tienda",
				},
				groupP73: {
					op1: "15 a 25 años",
					op2: "26 a 35 años",
					op3: "36 a 45 años",
					op4: "46 a 55 años",
					op5: "56 a 65 años",
					op6: "Más de 66 años",
				},
				groupP74: [
					{ text: "Hijos de 0 a 3 años", id: 1, isChecked: false },
					{ text: "Hijos de 4 a 14 años", id: 2, isChecked: false },
					{ text: "Hijos mayores de 14 años", id: 3, isChecked: false },
					{ text: "Sin Hijos", id: 4, isChecked: false },
				],
			},
			aspectos: [
				{ id: 1, text: "Confianza y credibilidad", isChecked: false },
				{ id: 2, text: "Recomendación del personal", isChecked: false },
				{ id: 3, text: "Atencíon personalizada", isChecked: false },
				{ id: 5, text: "Promociones", isChecked: false },
				{ id: 6, text: "Precios", isChecked: false },
				{ id: 7, text: "Calidad de los productos", isChecked: false },
				{ id: 8, text: "Surtido de productos", isChecked: false },
				{ id: 10, text: "Proximidad", isChecked: false },
				{ id: 11, text: "Tarjeta de cliente", isChecked: false },
				{ id: 12, text: "Devolución", isChecked: false },
				{ id: 13, text: "Tiempo de espera", isChecked: false },
				{ id: 14, text: "Servicio entrega a domicilio", isChecked: false },
				{ id: 15, text: "Compra Online", isChecked: false },
				{ id: 16, text: "Acceso, parking", isChecked: false },
				{ id: 17, text: "Acondicionamiento de la tienda", isChecked: false },
				{ id: 18, text: "Horario de apertura", isChecked: false },
			],
			aspectosMarcas: [
				{ id: 1, text: "Prestigio de la marca", isChecked: false },
				{ id: 2, text: "Confianza Y Seguridad", isChecked: false },
				{ id: 3, text: "Variedad de productos", isChecked: false },
				// { id: 4, text: "Sabor", isChecked: false },
				// { id: 5, text: "Olor / fragancia", isChecked: false },
				// { id: 6, text: "Textura y Suavidad", isChecked: false },
				// { id: 7, text: "Natural y Sano", isChecked: false },
				{ id: 8, text: "Diseño", isChecked: false },
				{ id: 9, text: "Tamaño", isChecked: false },
				{ id: 10, text: "Facilidad de uso", isChecked: false },
				{ id: 11, text: "Eficacia y Funcionamiento", isChecked: false },
				{ id: 12, text: "Calidad del producto", isChecked: false },
				{ id: 13, text: "Precio", isChecked: false },
				{ id: 14, text: "Promociones y Ofertas", isChecked: false },
				{ id: 15, text: "Otros motivos", isChecked: false },
				{ id: 16, text: "Nada que reseñar", isChecked: false },
			],
		};
		(this.url["eng"] = {
			idioma: "eng",
			preguntas: {
				p1: " From 0 to 10, how would you rate your satisfaction with respect to your visit?",
				p2: "Which sections have you visited today?",
				p3: "Which sentene describes better your experience today?",
				p4: "From 0 to 10, how would you rate...?",
				p4b: "Why didn´t you made a purchase?",
				p41: "From 0 to 10, how would you rate...?",
				p5: "From 0 to 10, Would you recomend our store to a friends or relatives?           ",
				p6Promotor: "What aspects do you highlight when you recommend us?",
				p6Neutral:
					"What aspects should we improve so that you recommend us more?",
				p6Detractor: "Why don't you recommend us?",
				p71: "Are you...",
				p72: "How often do you visit us?",
				p73: "Indícanos tu edad",
				p74: "There are children in the home",
				p75: "How many adults live in the household?",
				p8: "Of these brands, which is the last one you bought?           ",
				p9: "From 0 to 10, Would you recomend this brand to a friends or relatives?           ",
				p10Promotor:
					"What aspects do you highlight when you recommend the brand?",
				p10Neutral:
					"What aspects should the brand improve so that you recommend us more?",
				p10Detractor: "Why don't you recommend the brand?",
			},
			informativo: {
				text1: "Did you like Worten? ",
				text2:
					"Tell us your experience and get a 20€ discount for your next purchase!",
				text3: "Click here to leave your feedback. It will take only 30 secons",
				text4: " Thanks for participate! Your opinion helps us to improve",
				text5: "Thank you!",
				text6:
					"Now you just have to leave us your information if you want to receive all our news and promotions",
				text7: "Continue",
				text8: "Give us your email to send you the promotional code",
				text9:
					"I wish to receive promotional codes, information regarding offers, digital notifications, newsletters, promotion of events and generic or targeted offers, through the email address that I provide",
				text10: "Acceptance of Privacy Policy",
				text11: "Enter your email account",
				text12:
					"Thank you for collaborating, your opinion helps us to improve. We hope to see you again soon.",
				text13: "None of these",
			},
			categories: [
				{ id: 51, text: "Blig appliances", isChecked: false },
				{ id: 53, text: "Image and Sound", isChecked: false },
				{ id: 55, text: "Telephony", isChecked: false },
				{ id: 53, text: "Photography", isChecked: false },
				{ id: 99, text: "Worten Soluciona" },
				{ id: 56, text: "Little appliances", isChecked: false },
				{ id: 54, text: "Computing", isChecked: false },
				{ id: 57, text: "Accessories", isChecked: false },
				{ id: 52, text: "Games and consoles", isChecked: false },
				{
					id: 98,
					text: "Other Products",
					isChecked: false,
				},
			],
			options: {
				groupP3: {
					op1: "I bought a product and I received attention",
					op2: " I did not buy but I received attention",
					op3: " I bought a product but I did not receive attention",
					op4: "I did not buy or received attention ",
				},
				groupP4: {
					op1: "Availability level and attention received ",
					op2: "Did the workers pay attention yo your needs and to the use you were going to give to the product? ",
					op3: "Did they offer suitable products and explained its characteristics and advantages?",
					op4: "Did the workers know how to solve all you inquiries and questions? ",
					op5: "Did they offer accessories, services or suggested to visit another sections?",
				},
				groupP4b: {
					op1: "I just came for looking",
					op2: "I was not attended ",
					op3: "I didn´t find the product i was searching for",
					op4: "The price didn´t seem attractive to me",
				},
				groupP41: {
					op1: "Variety of the products in the store",
					op2: "Order and cleaning of the store",
					op3: "The products quality/price relation",
					op4: "Waiting times to be attended",
				},
				groupP72: {
					op1: "Montly",
					op2: "Every 3 to 6 months",
					op3: "Less frequently",
					op4: "This is the first time i visited Worten",
				},
				groupP73: {
					op1: "15 to 25 years",
					op2: "26 to 35 years",
					op3: "36 to 45 years",
					op4: "46 to 55 years",
					op5: "56 to 65 years",
					op6: "more than 66 years",
				},
				groupP74: {
					op1: "Children from 0 to 3 years",
					op2: "Children from 4 to 14 years",
					op3: "Children over 14 years old",
					op4: "Childless",
				},
			},
			aspectos: [
				{ id: 1, text: "Trust and credibility", isChecked: false },
				{ id: 2, text: "Staff recommendation", isChecked: false },
				{ id: 3, text: "Personalized attention", isChecked: false },
				{ id: 4, text: "I can compare prices", isChecked: false },
				{ id: 5, text: "Promotions", isChecked: false },
				{ id: 6, text: "Price", isChecked: false },
				{ id: 7, text: "Quality of the products", isChecked: false },
				{ id: 8, text: "Product assortment", isChecked: false },
				{ id: 9, text: "Variety of brands", isChecked: false },
				{ id: 10, text: "Proximity", isChecked: false },
				{ id: 11, text: "Customer card", isChecked: false },
				{ id: 12, text: "Return", isChecked: false },
				{ id: 13, text: "Wait time", isChecked: false },
				{ id: 14, text: "Home delivery service", isChecked: false },
				{ id: 15, text: "Online shopping", isChecked: false },
				{ id: 16, text: "Parking", isChecked: false },
				{ id: 17, text: "Store conditioning", isChecked: false },
				{ id: 18, text: "Schedule", isChecked: false },
				{ id: 99, text: "Nothing to reviewr", isChecked: false },
			],
			aspectosMarcas: [
				{ id: 1, text: "Brand Prestige", isChecked: false },
				{ id: 2, text: "Trust And Security", isChecked: false },
				{ id: 3, text: "Product variety", isChecked: false },
				// {id: 4, text: "Flavor", isChecked: false},
				// {id: 5, text: "Smell / fragrance", isChecked: false},
				// {id: 6, text: "Texture and Smoothness", isChecked: false},
				// {id: 7, text: "Natural and Healthy", isChecked: false},
				{ id: 8, text: "Design", isChecked: false },
				{ id: 9, text: "Size", isChecked: false },
				{ id: 10, text: "Ease of use", isChecked: false },
				{ id: 11, text: "Efficiency and Operation", isChecked: false },
				{ id: 12, text: "Product quality", isChecked: false },
				{ id: 13, text: "Price", isChecked: false },
				{ id: 14, text: "Promotions and Offers", isChecked: false },
				{ id: 15, text: "Other reasons", isChecked: false },
				{ id: 16, text: "Nothing to review", isChecked: false },
			],
		}),
			(this.url["por"] = {
				idioma: "por",
				preguntas: {
					p1: " Numa escala de 0 a 10, qual o grau de satisfação global com a sua visita à loja?            ",
					p2: "Indique-nos, por favor, que áreas da loja visitou hoje.",
					p3: " Diga-nos, por favor, qual das seguintes frases melhor descreve a sua visita hoje?",
					p4: "Numa escala de 0 a 10, como avalia:",
					p4b: "Por que razão não realizou uma compra?",
					p41: "Numa escala de 0 a 10, como avalia: ",
					p5: "Em uma escala de 0 a 10, você recomendaria nossa loja para um amigo ou parente?",
					p6Promotor: "Que aspectos você destaca ao nos recomendar?",
					p6Neutral:
						"Que aspectos devemos melhorar para que você nos recomende mais?",
					p6Detractor: "Por que você não nos recomenda?",
					p71: "Indique o seu género",
					p72: "Com que frequência visita a Worten?",
					p73: "Indique-nos a sua idade",
					p74: "Tem crianças em casa",
					p75: "Quantos adultos vivem na casa?",
					p8: "Dessas marcas, qual foi a última que você comprou?",
					p9: "Em uma escala de 0 a 10, quanto você recomenda a marca para um amigo ou parente?            ",
					p10Promotor: "Que aspectos você destaca ao recomenda a marca?",
					p10Neutral:
						"Que aspectos a marca deve melhorar para que ela seja mais recomendada? ",
					p10Detractor: "Por que você não  recomenda a marca?",
				},
				informativo: {
					text1: "Você gostou da Worten? ",
					text2:
						" Conte-nos a sua experiência e ganhe 20 € de desconto na sua próxima compra!",
					text3:
						"Clique aqui para deixar seu feedback. Vai demorar apenas 30 segundos",
					text4: "Obrigado por participar! Sua opinião nos ajuda a melhorar ",
					text5: "Obrigado!",
					text6:
						"Agora você só precisa nos deixar seus dados se deseja receber todas as nossas novidades e promoções",
					text7: "Continuar",
					text8: "Indique o seu email para enviar o código promocional",
					text9:
						"Desejo receber códigos promocionais, informações sobre ofertas, notificações digitais, newsletters, promoção de eventos e ofertas genéricas ou direcionadas, através do endereço de email que forneço",
					text10: "Aceitação da Política de Privacidade",
					text11: "Digite sua conta de e-mail",
					text12:
						"Obrigado por colaborar, sua opinião nos ajuda a melhorar. Nós esperamos te ver novamente em breve.",
					text13: "Nenhum desses",
				},
				categories: [
					{ id: 51, text: "Aparelhos grandes", isChecked: false },
					{ id: 53, text: "Imagem e som", isChecked: false },
					{ id: 55, text: "Telefonia", isChecked: false },
					{ id: 53, text: "Fotografia", isChecked: false },
					{ id: 99, text: "Worten Soluciona" },
					{ id: 56, text: "Aparelhos pequenos ", isChecked: false },
					{ id: 54, text: "Computadores", isChecked: false },
					{ id: 57, text: "Acessórios", isChecked: false },
					{ id: 52, text: "Jogos e consoles", isChecked: false },
					{
						id: 98,
						text: "Outros produtos",
						isChecked: false,
					},
				],
				options: {
					groupP3: {
						op1: "Comprei um produto e fui atendido por um colaborador.",
						op2: "Não comprei nada, mas fui atendido por um colaborador.",
						op3: "Comprei um produto sem ser atendido por um colaborador.",
						op4: " Não comprei nada nem fui atendido por um colaborador.",
					},
					groupP4: {
						op1: "Nível de disponibilidade e atendimento recebido",
						op2: "Os colaboradores preocuparam-se em conhecer as suas necessidades e o uso que pretendia dar ao equipamento/serviço",
						op3: "Os colaboradores indicaram-lhe produtos adequados e explicaram-lhe as suas características e potencialidades?",
						op4: "Os colaboradores conseguiram responder às suas dúvidas e questões? ",
						op5: "Os colaboradores sugeriram-lhe acessórios, serviços e/ou visitar outras áreas da loja?",
					},
					groupP4b: {
						op1: "Vim apenas ver ou dar um passeio",
						op2: "Não fui atendido por nenhum colaborador",
						op3: "Não encontrei o que procurava",
						op4: "O preço não me pareceu atrativo",
					},
					groupP41: {
						op1: "Oferta e variedade de produtos na loja",
						op2: "Organização e limpeza da loja",
						op3: "Relação qualidade/preço dos produtos",
						op4: "Tempo de espera para ser atendido",
					},
					groupP72: {
						op1: "Todos os meses",
						op2: "Em cada 3 a 6 meses",
						op3: "Com menos frequência",
						op4: "É a primeira vez que visito a Worten",
					},
					groupP73: {
						op1: "15 a 25 anos",
						op2: "26 a 35 anos",
						op3: "36 a 45 anos",
						op4: "46 a 55 anos",
						op5: "56 a 65 anos",
						op6: "Mais de 55 anos",
					},
					groupP74: {
						op1: "Crianças de 0 a 3 anos",
						op2: "Crianças de 4 a 14 anos",
						op3: "Crianças maiores de 14 anos",
						op4: "Sem filhos",
					},
				},
				aspectos: [
					{ id: 1, text: "Confiança e credibilidade", isChecked: false },
					{ id: 2, text: "Recomendação da equipe", isChecked: false },
					{ id: 3, text: "Atenção personalizada", isChecked: false },
					{ id: 4, text: "Eu posso comparar preços", isChecked: false },
					{ id: 5, text: "Promoções", isChecked: false },
					{ id: 6, text: "Preço", isChecked: false },
					{ id: 7, text: "Qualidade dos produtos", isChecked: false },
					{ id: 8, text: "Variedade de productos", isChecked: false },
					{ id: 9, text: "Variedade de marcas", isChecked: false },
					{ id: 10, text: "Proximidade", isChecked: false },
					{ id: 11, text: "Cartão de cliente", isChecked: false },
					{ id: 12, text: "Devolução ", isChecked: false },
					{ id: 13, text: "Tempo de espera", isChecked: false },
					{ id: 14, text: "Serviço de entrega ao domicílio", isChecked: false },
					{ id: 15, text: "Compras online", isChecked: false },
					{ id: 16, text: "Acesso e estacionamento", isChecked: false },
					{ id: 17, text: "Condicionamento da loja", isChecked: false },
					{ id: 18, text: "Horário de abertura", isChecked: false },
					{ id: 99, text: "Nada", isChecked: false },
				],
				aspectosMarcas: [
					{ id: 1, text: "Prestígio de marca", isChecked: false },
					{ id: 2, text: "Confiança e segurança", isChecked: false },
					{ id: 3, text: "Variedade do produto", isChecked: false },
					// {id: 4, text: "Flavor", isChecked: false},
					// {id: 5, text: "Smell / fragrance", isChecked: false},
					// {id: 6, text: "Texture and Smoothness", isChecked: false},
					// {id: 7, text: "Natural and Healthy", isChecked: false},
					{ id: 8, text: "Projeto", isChecked: false },
					{ id: 9, text: "Tamanho", isChecked: false },
					{ id: 10, text: "Facilidade de uso", isChecked: false },
					{ id: 11, text: "Eficiência e operação", isChecked: false },
					{ id: 12, text: "Qualidade do produto", isChecked: false },
					{ id: 13, text: "Preço", isChecked: false },
					{ id: 14, text: "Promoções e ofertas", isChecked: false },
					{ id: 15, text: "Outros motivos", isChecked: false },
					{ id: 16, text: "Nada para revisar", isChecked: false },
				],
			}),
			(this.url["cat"] = {
				idioma: "cat",
				preguntas: {
					p1: " En una escala del 0 al 10, com valoraríes la teva satisfacció general respecte a la teva visita d’avui?",
					p2: "Indica’ns, per favor, quines seccions has visitat avui",
					p3: "Digués, per favor, quina de les següents frases descriu millor la teva visita a la botiga:",
					p4: "Respecte a la teva  visita a la botiga, en una escala del 0 al 10 valoraríes…?",
					p4b: "Per quin motiu no has realitzat una compra?",
					p41: "Respecte a la teva  visita a la botiga, en una escala del 0 al 10 valoraríes…?",
					p5: "En una escala del 0 al 10, recomanaries a un amic o familiar la nostra botiga?",
					p6Promotor: "Quins aspectes destaques quan ens recomanes?",
					p6Neutral:
						"Quins aspectes hauríem de millorar per què ens recomanesis més?",
					p6Detractor: "Per què no ens recomanaríes?",
					p71: "Quins aspectes destaques quan ens recomanes?",
					p72: "Amb quina freqüència ens visites?",
					p73: "Indica’ns la teva edat",
					p74: "Tens fills?",
					p75: "Quants adults viuen al teu hogar",
					p8: "D’aquestes marques, quina es l’última que has comprat?",
					p9: "En una escala del 0 al 10, quant recomanes a un amic o familiar aquesta marca?",
					p10Promotor: "Quins aspectes destaques quan recomanes la marca?",
					p10Neutral:
						"Quins aspectes hauría de millorar la marca per que la recomanesis més?",
					p10Detractor: "Per que no recomanaries la marca?",
				},
				informativo: {
					text1: "T’ha agradat Worten?",
					text2:
						"Explica’ns la teva experiència i aconsegueix un descompte de 20€ per la teva propera compra",
					text3:
						"Fes clic aquí per deixar-nos la teva opinió. Només et portarà 30 segons.",
					text4: "Gràcies per participar! La teva opinió ens ajuda a millorar ",
					text5: "Gràcies!",
					text6:
						"Ara només necessiteu deixar-nos les vostres dades si voleu rebre totes les nostres novetats i promocions",
					text7: "Continua",
					text8:
						"Indica’ns el teu correu electronic per enviar-te el codi promocional",
					text9:
						"Desitjo rebre codis promocionals informació relativa a ofertes, notificacions digitals, newsletters, promoció d'esdeveniments i d'ofertes genèriques o direccionades, a través de l'adreça de correu electrònic que va proporcionar",
					text10: "Acceptació de Política de Privacitat",
					text11: "Ingressa el teu compte de correu",
					text12:
						"Gràcies per col·laborar, la teva opinió ens ajuda a millorar. Esperem tornar a veure't aviat",
					text13: "Ninguna d’aquestes",
				},
				categories: [
					{ id: 51, text: "Gran electrodomèstic", isChecked: false },
					{ id: 53, text: "Imatge i so", isChecked: false },
					{ id: 55, text: "Telefonía", isChecked: false },
					{ id: 53, text: "Fotografía", isChecked: false },
					{ id: 99, text: "Worten Soluciona" },
					{ id: 56, text: "Petits electrodomèstics ", isChecked: false },
					{ id: 54, text: "Informática", isChecked: false },
					{ id: 57, text: "Accessoris", isChecked: false },
					{ id: 52, text: "Videoconsoles", isChecked: false },
					{
						id: 98,
						text: "Altres productes",
						isChecked: false,
					},
				],
				options: {
					groupP3: {
						op1: "Vaig comprar un producte i rebre atenció",
						op2: "No vaig comprar pero vaig rebre atenció",
						op3: "Vaig comprar un producte sense ser atés",
						op4: "No vaig comprar i no vaig rebre atenció",
					},
					groupP4: {
						op1: "El nivell de Disponibilitat i tracte rebut",
						op2: "Es varen preocupar per conèixer les teves necessitats i l’ús que li donaràs ",
						op3: "Et varen oferir productes adeqüats i et varen explicar les seves característiques i avantatges?",
						op4: "Varen sebre resoldre els teus dubtes o consultes?  ",
						op5: "El varen oferir accessoris, serveis i/o visites a altres seccions?",
					},
					groupP4b: {
						op1: "Anava només a pasetjar",
						op2: "No m’han atés",
						op3: "No he trobat el producte que estava cercant",
						op4: "El preu no m’ha parescut atractiu",
					},
					groupP41: {
						op1: "Surtit i varietat dels productes en la botiga",
						op2: "Ordre i netetja de la botiga",
						op3: "Relació de la qualitat/preu dels productes",
						op4: "Temps d’espera en ser atés ",
					},
					groupP72: {
						op1: "Tots els mesos",
						op2: "Entre 3 i 6 mesos",
						op3: "Amb menor freqüència",
						op4: "Es la primera vegada que visitava la botiga",
					},
					groupP73: {
						op1: "15 a 25 anys",
						op2: "26 a 35 anys",
						op3: "36 a 45 anys",
						op4: "46 a 55 anys",
						op5: "56 a 65 anys",
						op6: "Més  de 55 anys",
					},
					groupP74: {
						op1: "Fills de 0 a 3 anys",
						op2: "Fills de 4 a 14 anys",
						op3: "Fills menors de 14 anys",
						op4: "Sense fills",
					},
				},
				aspectos: [
					{ id: 1, text: "Confiança i credibilitat", isChecked: false },
					{ id: 2, text: "Puc comparar preus", isChecked: false },
					{ id: 3, text: "Atenció personalitzada", isChecked: false },
					{ id: 4, text: "Puc comparar preus", isChecked: false },
					{ id: 5, text: "Promoció", isChecked: false },
					{ id: 6, text: "Preu", isChecked: false },
					{ id: 7, text: "Qualitat dels productes", isChecked: false },
					{ id: 8, text: "Varietat de productes", isChecked: false },
					{ id: 9, text: "Varietat de marques", isChecked: false },
					{ id: 10, text: "Proximitat", isChecked: false },
					{ id: 11, text: "Targeta de client", isChecked: false },
					{ id: 12, text: "Devolució ", isChecked: false },
					{ id: 13, text: "Temps d’espera", isChecked: false },
					{ id: 14, text: "Servei d’entrega a domicili", isChecked: false },
					{ id: 15, text: "Compra Online", isChecked: false },
					{ id: 16, text: "Accés al parquing", isChecked: false },
					{ id: 17, text: "Condicionament de la botiga", isChecked: false },
					{ id: 18, text: "Horari d’apertura", isChecked: false },
					{ id: 99, text: "Res a ressenyar", isChecked: false },
				],
				aspectosMarcas: [
					{ id: 1, text: "Prestigi de la marca", isChecked: false },
					{ id: 2, text: "Confiança i Seguretat", isChecked: false },
					{ id: 3, text: "Varietat de productes", isChecked: false },
					// { id: 4, text: "Sabor", isChecked: false },
					// { id: 5, text: "Olor / fragancia", isChecked: false },
					// { id: 6, text: "Textura y Suavidad", isChecked: false },
					// { id: 7, text: "Natural y Sano", isChecked: false },
					{ id: 8, text: "Disseny", isChecked: false },
					{ id: 9, text: "Mida", isChecked: false },
					{ id: 10, text: "Facilitat d'ús", isChecked: false },
					{ id: 11, text: "Eficàcia i Funcionament", isChecked: false },
					{ id: 12, text: "Qualitat del producte", isChecked: false },
					{ id: 13, text: "Preu", isChecked: false },
					{ id: 14, text: "Promocions i Ofertes", isChecked: false },
					{ id: 15, text: "Altres motius", isChecked: false },
					{ id: 16, text: "Res a ressenyar", isChecked: false },
				],
			}),
			(this.url["rus"] = {
				idioma: "rus",
				preguntas: {
					p1: " По шкале от 0 до 10, как бы вы в целом оценили свой визит? ",
					p2: "Расскажите, пожалуйста, какие разделы вы посетили сегодняi",
					p3: "Сообщите нам, какая из следующих фраз лучше всего описывает ваш визит сегодня ",
					p4: "Как бы вы оценили по шкале от 0 до 10:",
					p4b: "По какой причине вы не совершили покупку?",
					p41: "Как бы вы оценили по шкале от 0 до 10:",
					p5: "По шкале от 0 до 10 вы бы порекомендовали наш магазин другу или члену семьи?",
					p6Promotor: "Какие аспекты вы выделяете, когда рекомендуете нас?",
					p6Neutral:
						"Какие аспекты мы должны улучшить, чтобы вы рекомендовали нас больше?",
					p6Detractor: "Почему вы нас не порекомендуете?",
					p71: "Укажите ваш пол",
					p72: "Как часто вы нас навещаете?",
					p73: "Скажите нам ваш возраст",
					p74: "Иметь детей дома",
					p75: "Сколько взрослых живет в семье?",
					p8: "Какие из следующих брендов вы приобрели за последние 3 месяца?",
					p9: "По шкале от 0 до 10, насколько вы рекомендуете этот бренд другу или члену семьи?",
					p10Promotor: "Какие аспекты вы выделяете, когда рекомендуете бренд?",
					p10Neutral:
						"Какие аспекты следует улучшить бренду, чтобы вы его больше рекомендовали?",
					p10Detractor: "Почему вы не рекомендуете этот бренд?",
				},
				informativo: {
					text1: "Вам нравится Вортен?",
					text2: "Расскажите нам о своем опыте и помогите нам стать лучше!",
					text3:
						"Нажмите здесь, чтобы оставить нам свое мнение. Это займет у вас всего 30 секунд.",
					text4: "Спасибо за участие! Ваше мнение помогает нам стать лучше",
					text5: "Спасибо!",
					text6:
						"Теперь вам просто нужно оставить нам свою информацию, если вы хотите получать все наши новости и акции.",
					text7: "Продолжать",
					text8:
						"Отправьте нам свой адрес электронной почты, чтобы мы отправили вам промокод",
					text9:
						"Я хочу получать информацию о предложениях, цифровых уведомлениях, информационных бюллетенях, рекламы мероприятий и общих или направленных предложениях на адрес электронной почты, которой я предоставляю",
					text10: "Принятие политики конфиденциальности",
					text11: "Войдите в свой аккаунт",
					text12:
						"Спасибо за сотрудничество, ваше мнение помогает нам становиться лучше. Мы надеемся увидеть вас скоро.",
					text13: "Ninguna de estas",
				},
				categories: [
					{ id: 51, text: "бытовая техника", isChecked: false },
					{ id: 53, text: "Изображение и звук", isChecked: false },
					{ id: 55, text: "Телефония", isChecked: false },
					{ id: 53, text: "Фотография", isChecked: false },
					{ id: 99, text: "Вортен решает" },
					{ id: 56, text: "Маленькая техника ", isChecked: false },
					{ id: 54, text: "Вычисление", isChecked: false },
					{ id: 57, text: "Аксессуары", isChecked: false },
					{ id: 52, text: "Игры и приставки", isChecked: false },
					{
						id: 98,
						text: "Другие продукты",
						isChecked: false,
					},
				],
				options: {
					groupP3: {
						op1: "Я купил товар и получил внимание",
						op2: "Я не покупал, но получил внимание",
						op3: "Я купил товар, но меня не обслужили",
						op4: "Я не покупал и не получил внимания",
					},
					groupP4: {
						op1: "Уровень доступности и обслуживания",
						op2: "Они заботились о ваших потребностях и о том, как вы будете товар использовать.",
						op3: " Предложили ли они вам подходящие продукты и объяснили их характеристики и преимущества?",
						op4: "Знали ли они, как ответить на ваши вопросы?",
						op5: "Предлагали ли они вам аксессуары, услуги и / или посетить другие разделы?",
					},
					groupP4b: {
						op1: "Я пришёл просто посмотреть или погулять",
						op2: "Меня не обслужили",
						op3: "Я не нашел продукт, который я искал",
						op4: "Цена мне не показалась привлекательной",
					},
					groupP41: {
						op1: "Ассортимент и разнообразие товаров в магазине.",
						op2: "Порядок и чистота в магазине.",
						op3: "Соотношение цена / качество продуктов.",
						op4: "Время ожидания обслуживания ",
					},
					groupP72: {
						op1: "Каждый месяц",
						op2: "От 3 до 6 месяцев",
						op3: "Реже",
						op4: "Я впервые приезжаю в Вортен",
					},
					groupP73: {
						op1: "Между 16 и 25 лет",
						op2: "Между 26 и 35 лет",
						op3: "Между36 и 45 лет",
						op4: "Между46 и 55 лет",
						op5: "Между56 и 65 лет",
						op6: "Больше  65 лет",
					},
					groupP74: {
						op1: "Дети от 0 до 3 лет",
						op2: "Дети от 4 до 14 лет",
						op3: "Дети старше 14 лет",
						op4: "Детей нет",
					},
				},
				aspectos: [
					{ id: 1, text: "Доверие и авторитет", isChecked: false },
					{ id: 2, text: "Советы персонала", isChecked: false },
					{ id: 3, text: "Индивидуальное внимание", isChecked: false },
					{ id: 4, text: "Я могу сравнить цены", isChecked: false },
					{ id: 5, text: "Промо акции", isChecked: false },
					{ id: 6, text: "Цена", isChecked: false },
					{ id: 7, text: "Качество продуктов", isChecked: false },
					{ id: 8, text: "Ассортимент товаров", isChecked: false },
					{ id: 9, text: "Разнообразие брендов", isChecked: false },
					{ id: 10, text: "Близость", isChecked: false },
					{ id: 11, text: "Карточка клиента", isChecked: false },
					{ id: 12, text: "Возврат денег", isChecked: false },
					{ id: 13, text: "Время ожидания", isChecked: false },
					{ id: 14, text: "Доставка на дом", isChecked: false },
					{ id: 15, text: "Онлайн шоппинг", isChecked: false },
					{ id: 16, text: "Доступ, парковка", isChecked: false },
					{ id: 17, text: "Кондиционирование магазина", isChecked: false },
					{ id: 18, text: "Часы работы", isChecked: false },
					{ id: 99, text: "Без рекомендаций", isChecked: false },
				],
				aspectosMarcas: [
					{ id: 1, text: "Престиж бренда", isChecked: false },
					{ id: 2, text: "Доверие и безопасность", isChecked: false },
					{ id: 3, text: "Разнообразие продуктов", isChecked: false },
					// { id: 4, text: "Sabor", isChecked: false },
					// { id: 5, text: "Olor / fragancia", isChecked: false },
					// { id: 6, text: "Textura y Suavidad", isChecked: false },
					// { id: 7, text: "Natural y Sano", isChecked: false },
					{ id: 8, text: "Дизайн", isChecked: false },
					{ id: 9, text: "Размер", isChecked: false },
					{ id: 10, text: "Простота использования", isChecked: false },
					{
						id: 11,
						text: "Эффективность и производительность",
						isChecked: false,
					},
					{ id: 12, text: "Качество продукта", isChecked: false },
					{ id: 13, text: "Цена", isChecked: false },
					{ id: 14, text: "Акции и предложения", isChecked: false },
					{ id: 15, text: "Другие причины", isChecked: false },
					{ id: 16, text: "Без рекомендаций", isChecked: false },
				],
			}),
			(this.url["ger"] = {
				idioma: "rus",
				preguntas: {
					p1: " En una escala de 0 a 10 ¿Cómo valorarías tu satisfacción general con respecto a tu última visita a Worten? ",
					p2: " Indícanos por favor que secciones has visitado hoy",
					p3: "Dinos por favor cuál de las siguientes frases describe mejor tu última visita a la tienda:",
					p4: "Respecto a tu última visita a la tienda ¿En una escala de 0 a 10 cómo valorarías...?",
					p4b: "¿Por qué motivo no has realizado una compra?",
					p41: "Respecto a tu última visita a la tienda ¿En una escala de 0 a 10 cómo valorarías...?",
					p5: "¿En una escala de 0 a 10 recomendarías a un amigo o familiar nuestra tienda?",
					p6Promotor: "¿Qué aspectos destacas cuando nos recomiendas?",
					p6Neutral:
						"¿Qué aspectos deberíamos mejorar para que nos recomiendes más?",
					p6Detractor: "¿Por qué no nos recomiendas? ",
					p71: "Eres…",
					p72: "¿Con qué frecuencia nos visitas?",
					p73: "Indícanos tu edad",
					p74: "Hay niños en el hogar",
					p75: "¿Cuántos adultos viven en el hogar?",
					p8: "De estas marcas ¿cuál es la última que has comprado?",
					p9: "¿En una escala de 0 a 10 cuánto recomiendas a un amigo o familiar esta marca?",
					p10Promotor: "¿Qué aspectos destacas cuando recomiendas la marca?",
					p10Neutral:
						"¿Qué aspectos debería mejorar la marca para que la recomiendes más?",
					p10Detractor: "¿Por qué no recomienda la marca ?",
				},
				informativo: {
					text1: "Вам нравилось носить?",
					text2: "Расскажите нам о своем опыте и помогите нам стать лучше",
					text3:
						"Haz click aquí para dejarnos tu opinión. Solo te llevará 30 segundos.",
					text4: "¡Gracias por participar! Tu opinión nos ayuda a mejorar",
					text5: "¡Gracias!",
					text6:
						"Ahora solo tienes que dejarnos tus datos si quieres recibir todas nuestras novedades y promociones",
					text7: "Continuar",
					text8:
						"Indícanos si deseas tu correo electrónico y pulsa continuar para terminar la encuesta.",
					text9:
						"Deseo recibir información relativa a ofertas, notificaciones digitales, newsletters, promoción de eventos y de ofertas genéricas o direccionadas, a través de la dirección de correo electrónico que proporciono",
					text10: "Aceptación de Política de Privacidad",
					text11: "Ingresa tu cuenta de correo",
					text12:
						"Gracias por colaborar, tu opinión nos ayuda a mejorar. Esperamos volver a verte pronto",
				},
				categories: [
					{ id: 51, text: "Grandes Electrodomésticos", isChecked: false },
					{ id: 53, text: "Imagen y Sonido", isChecked: false },
					{ id: 55, text: "Telefonía", isChecked: false },
					{ id: 53, text: "Fotografía", isChecked: false },
					{ id: 99, text: "Worten Soluciona" },
					{ id: 56, text: "Pequeños Electrodomésticos", isChecked: false },
					{ id: 54, text: "Informatica", isChecked: false },
					{ id: 57, text: "Accesorios", isChecked: false },
					{ id: 52, text: "Juegos y Consolas", isChecked: false },
					{
						id: 98,
						text: "Otros productos",
						isChecked: false,
					},
				],
				options: {
					groupP3: {
						op1: " compré un producto y recibí atención ",
						op2: "No compré pero recibí atención",
						op3: "Compré un producto sin que me atendiesen ",
						op4: "No compré y no recibí atención",
					},
					groupP4: {
						op1: "Nivel de disponibilidad y trato recibido ",
						op2: "Se preocuparon por conocer tu necesidades y el uso que le darás ",
						op3: "¿Te ofrecieron productos adecuados y te explicaron sus características y ventajas? ",
						op4: "¿Supieron resolver tus dudas o consultas? ",
						op5: "¿Te ofrecieron accesorios, servicios y/o visitar otras secciones?",
					},
					groupP4b: {
						op1: "Venía solo mirar o dar un paseo",
						op2: "No me han atendido",
						op3: "No encontré el producto que estaba buscando",
						op4: "El precio no me ha parecido atractivo",
					},
					groupP41: {
						op1: "Surtido y variedad de los productos en la tienda",
						op2: "Orden y limpieza de la tienda",
						op3: "Relación de la calidad /precio de los productos",
						op4: "Tiempo de espera en ser atendido",
					},
					groupP72: {
						op1: "Todos los meses",
						op2: "Entre 3 y 6 meses",
						op3: "Con menos frecuencia",
						op4: "Es la primera vez que visitaba la tienda",
					},
					groupP73: {
						op1: "15 a 25 años",
						op2: "26 a 35 años",
						op3: "36 a 45 años",
						op4: "46 a 55 años",
						op5: "56 a 65 años",
						op6: "Más de 66 años",
					},
					groupP74: {
						op1: "Hijos de 0 a 3 años",
						op2: "Hijos de 4 a 14 años",
						op3: "Hijos mayores de 14 años",
						op4: "Sin Hijos",
					},
				},
				aspectos: [
					{ id: 1, text: "Confianza y credibilidad", isChecked: false },
					{ id: 2, text: "Recomendación del personal", isChecked: false },
					{ id: 3, text: "Atencíon personalizada", isChecked: false },
					{ id: 4, text: "Puedo compara precios", isChecked: false },
					{ id: 5, text: "Promociones", isChecked: false },
					{ id: 6, text: "Precios", isChecked: false },
					{ id: 7, text: "Calidad de los productos", isChecked: false },
					{ id: 8, text: "Surtido de productos", isChecked: false },
					{ id: 9, text: "Variedad de marcas", isChecked: false },
					{ id: 10, text: "Proximidad", isChecked: false },
					{ id: 11, text: "Tarjeta de cliente", isChecked: false },
					{ id: 12, text: "Devolución", isChecked: false },
					{ id: 13, text: "Tiempo de espera", isChecked: false },
					{ id: 14, text: "Servicio entrega a domicilio", isChecked: false },
					{ id: 15, text: "Compra Online", isChecked: false },
					{ id: 16, text: "Acceso, parking", isChecked: false },
					{ id: 17, text: "Acondicionamiento de la tienda", isChecked: false },
					{ id: 18, text: "Horario de apertura", isChecked: false },
					{ id: 99, text: "Nada que reseñar", isChecked: false },
				],
				aspectosMarcas: [
					{ id: 1, text: "Prestigio de la marca", isChecked: false },
					{ id: 2, text: "Confianza Y Seguridad", isChecked: false },
					{ id: 3, text: "Variedad de productos", isChecked: false },
					// { id: 4, text: "Sabor", isChecked: false },
					// { id: 5, text: "Olor / fragancia", isChecked: false },
					// { id: 6, text: "Textura y Suavidad", isChecked: false },
					// { id: 7, text: "Natural y Sano", isChecked: false },
					{ id: 8, text: "Diseño", isChecked: false },
					{ id: 9, text: "Tamaño", isChecked: false },
					{ id: 10, text: "Facilidad de uso", isChecked: false },
					{ id: 11, text: "Eficacia y Funcionamiento", isChecked: false },
					{ id: 12, text: "Calidad del producto", isChecked: false },
					{ id: 13, text: "Precio", isChecked: false },
					{ id: 14, text: "Promociones y Ofertas", isChecked: false },
					{ id: 15, text: "Otros motivos", isChecked: false },
					{ id: 16, text: "Nada que reseñar", isChecked: false },
				],
			});
	}
	returidioma(a) {
		this.ngOnInit();
		console.log(this.url)
		let url = JSON.parse(localStorage.getItem("dataIdioma"));
		console.log(url);
		if (url != null) {
			this.url = url
		} 
		console.log(this.url, a);
		return this.url[a];
	}
	reset() {
		if (!this.url) {
			this.url = JSON.parse(localStorage.getItem("dataIdioma"));
		}
	}
	setText(data: any) {
		console.log(data);
		this.url = data.idioma;
		localStorage.setItem("dataIdioma", JSON.stringify(data.idioma));
	}
}
